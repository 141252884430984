<template>
  <div
    class="easy-input"
    :class="{ dark, isError }"
    key="input-type"
    @click="onClick"
  >
    <div class="easy-input__control" :class="{ isValid: currentValue.length }">
      {{ currentValue }}
    </div>
    <img class="easy-input__right-icon" src="@/assets/img/i-down.svg" />
    <hr class="easy-input__line" />
    <label class="easy-input__label">{{ label }}</label>
    <span v-if="isError" class="easy-input__error" key="input-error">{{
      errorText
    }}</span>
    <span
      v-else-if="description"
      class="easy-input__description"
      key="input-description"
      >{{ description }}</span
    >
  </div>
</template>

<script>
export default {
  name: "EasyPhantomInput",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
    },
    description: {
      type: String,
      default: "",
    },
    errorText: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    defaultType: {
      type: String,
      default: "text",
    },
    onClick: {
      type: Function,
      default: () => true,
    },
    currentValue: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.easy-input__right-icon {
  position: absolute;
  right: 0;
  top: 32px;
  transform: rotate(-90deg);
}
.easy-input__control {
  height: auto;
  min-height: 30px;
}
</style>
