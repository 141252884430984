<template>
  <div>
    <div class="auth-page__header">
      <div class="auth-page__title">{{ $t("signUpTitle") }}</div>
    </div>
    <form
      id="sign-up-form"
      method="post"
      novalidate="true"
      class="auth-page__form"
      @submit="onSubmit"
    >
      <easy-phantom-input
        :currentValue="selectedCountryName"
        :label="$t('profileHomeCountry')"
        :onClick="() => (isChangeCountryOpened = true)"
      />
      <easy-input
        v-model="phoneNumber"
        :label="$t('phoneReg')"
        defaultType="tel"
        :placeholder="phonePlaceholder"
        :mask="selectedPhoneMask"
        isPhoneNumber
        :onEnter="onSubmit"
        :errorText="$t('errPhone')"
        :isError="isSubmitted && this.unmaskedPhoneNumber.length < 9"
      />
      <easy-input
        v-model="username"
        :label="$t('yourName')"
        :onEnter="onSubmit"
        :errorText="$t('oldPasswordError')"
        :isError="isSubmitted && !username"
      />
      <easy-input
        v-model="password"
        :label="$t('password')"
        :errorText="$t('errPassword')"
        :onEnter="onSubmit"
        :isError="isSubmitted && !password"
        isPassword
        :autocomplete="'new-password'"
        :type="'password'"
      />
      <div class="auth-page__agreement">
        <div class="auth-page__agreement-checkbox">
          <input id="sign-up-agree" type="checkbox" v-model="isAgree" />
          <label for="sign-up-agree"></label>
        </div>
        <div class="auth-page__agreement-label">
          {{ $t("loginTerms1") }}
          <span @click="openTerms">{{ $t("loginTerms2") }}</span>
          {{ $t("loginTerms3") }}
          <span @click="openPublic">{{ $t("loginPublic") }}</span>
        </div>
      </div>
      <button
        :class="{ disabled: isNotValid, isLoading: isLoading }"
        class="btnWideRound"
      >
        {{ $t("btnNext") }}
      </button>
    </form>

    <easy-bottom-sheet
      :isOpened.sync="isChangeCountryOpened"
      :title="$t('changeCountryTitle')"
    >
      <div class="signup-country__container">
        <div
          class="signup-country__item"
          v-for="(v, i) in [
            countryCode,
            ...[285, 284, 308, 301, 304, ...fakeCountryList].filter(
              (v) => v !== countryCode
            ),
          ]"
          :key="i"
          @click="onChangeCountry(v)"
        >
          <div
            class="signup-country__checkbox"
            :class="{ isActive: v === countryCode }"
          >
            <img src="@/assets/img/i-success.svg" />
          </div>
          <div class="signup-country__label">{{ countryName[v] }}</div>
        </div>
      </div>
    </easy-bottom-sheet>
  </div>
</template>

<script>
import EasyInput from "@/components/common/EasyInput";
import EasyPhantomInput from "@/components/common/EasyPhantomInput";
import EasyBottomSheet from "@/components/common/EasyBottomSheet.vue";
import { openExternal } from "@/utils/utils";
import { mapActions, mapGetters } from "vuex";
import eventsRegister from "@/services/events-register";

const fakeList = Array.from({ length: 12 }, (_, i) => i);

export default {
  name: "SignUpForm",
  components: {
    EasyInput,
    EasyPhantomInput,
    EasyBottomSheet,
  },
  data() {
    return {
      phoneNumber: localStorage.getItem("loginPhone") || "",
      username: "",
      password: "",
      countryCode: 285,
      isAgree: false,
      fakeCountryList: fakeList,
      disabledCountryList: [304, ...fakeList],
      isChangeCountryOpened: false,

      isSubmitted: false,
      isLoading: false,
      countryCodes: {
        UA: 285,
        USA: 284,
        GB: 301,
        CA: 308,
      },
      countryName: {
        285: this.$t("Ukraine"),
        284: this.$t("USA"),
        304: this.$t("Poland"),
        308: this.$t("Canada"),
        301: this.$t("United Kingdom"),
        0: this.$t("France"),
        1: this.$t("Italy"),
        2: this.$t("Spain"),
        3: this.$t("Netherlands"),
        4: this.$t("Portugal"),
        5: this.$t("Greece"),
        6: this.$t("Hungary"),
        7: this.$t("Switzerland"),
        8: this.$t("Norway"),
        9: this.$t("Croatia"),
        10: this.$t("Latvia"),
        11: this.$t("Iceland"),
        12: this.$t("Australia"),
      },
      phoneMasks: {
        285: {
          mask: "+380-BP-PPP-PP-PP",
          tokens: {
            P: { pattern: /^[0-9]*\.?[0-9]*$/ },
            B: { pattern: /^([1-9][0-9]{0,1})$/ },
          },
        },
        284: {
          mask: "+1-PPP-PPP-PPPP",
          tokens: {
            P: { pattern: /^[0-9]*\.?[0-9]*$/ },
          },
        },
        301: {
          mask: "+44-PPPP-PPPPPP",
          tokens: {
            P: { pattern: /^[0-9]*\.?[0-9]*$/ },
          },
        },
        308: {
          mask: "+1-PPP-PPP-PPPP",
          tokens: {
            P: { pattern: /^[0-9]*\.?[0-9]*$/ },
          },
        },
      },
      nameMask: {
        mask: "CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC",
        tokens: {
          C: {
            pattern: /^[аАбБвВгГдДеЕёЁжЖзЗіІїЇиҐґИйЙкКл\-єЄЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ]+$/,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("root", ["userIPData"]),
    userLangSiteUrl() {
      const links = {
        285: "https://easyget.com.ua",
        284: "https://easyget.ai",
      };

      return links[this.countryCode];
    },
    isNotValid() {
      return (
        this.unmaskedPhoneNumber.length < 8 ||
        !this.username ||
        this.password.length < 6 ||
        !this.isAgree
      );
    },
    unmaskedPhoneNumber() {
      return this.phoneNumber.replace(/^(\+)|\D/g, "$1");
    },
    phonePlaceholder() {
      const masks = {
        US: "+1-XXX-XXX-XXXX",
        UA: "+380-XX-XXX-XX-XX",
        GB: "+44-PPPP-PPPPPP",
        CA: "+1-XXX-XXX-XXXX",
      };

      console.log(
        "this.userIPData.countryCode",
        masks[this.userIPData.countryCode]
      );

      return masks[this.userIPData.countryCode];
    },
    selectedCountryName() {
      return this.countryName[this.countryCode];
    },
    selectedPhoneMask() {
      return this.phoneMasks[this.countryCode];
    },
  },
  created() {
    this.countryCode = this.countryCodes[this.userIPData.countryCode] || 285;
  },
  methods: {
    ...mapActions("user", ["signUp"]),
    async onSubmit(e) {
      e.preventDefault();
      if (this.isLoading) return false;
      this.isSubmitted = true;

      if (!this.isNotValid) {
        this.isLoading = true;
        try {
          await this.signUp({
            username: this.username,
            phoneNumber: this.unmaskedPhoneNumber,
            password: this.password,
            countryCode: this.countryCode,
          });
          this.isLoading = false;
          this.$router.push("/sign-up/confirm");
        } catch (error) {
          const message = error.Message
            ? this.$t("phoneRegErrorExist")
            : this.$t("serverError");
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message,
          });
          this.isLoading = false;
        }
      }
    },
    onChangeCountry(country) {
      if (this.disabledCountryList.includes(country)) {
        return this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: this.$t("selectCountryCodeError"),
        });
      }

      this.countryCode = country;
      this.isChangeCountryOpened = false;
      this.phoneNumber = " ";
    },
    openTerms() {
      openExternal(this.userLangSiteUrl + "/poriadok-povernennia-tovariv");
    },
    openPublic() {
      openExternal(this.userLangSiteUrl + "/kliyentam");
    },
  },
};
</script>

<style lang="scss">
.signup-country__item {
  display: flex;
  align-items: center;
}
.signup-country__checkbox {
  border: 2px solid #bfbfbf;
  width: 22px;
  height: 22px;
  margin-right: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.signup-country__checkbox.isActive {
  background: #91c258;
  border: 2px solid#91C258;
}
.signup-country__label {
  border-bottom: 1px solid #f1f1f1;
  flex: 1 auto;
  padding-top: 18px;
  padding-bottom: 16px;
}
.signup-country__item:last-child .signup-country__label {
  border-bottom: 1px solid transparent;
}
.easy-bottom-sheet__content {
  max-height: 235px;
  overflow: scroll;
}
</style>
